<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-form ref="viewForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field :loading="loading" v-model="form.modbus_latency" :counter="5"
                        :rules="[rules.required]" :label="$store.getters.getTextMap().modbus_ping_latency" required
                        :dark="$store.getters.getColorPalette().isDark"></v-text-field>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-text-field :loading="loading" v-model="form.modbus_unhealthy_latency" :counter="5"
                        :rules="[rules.required]" :label="$store.getters.getTextMap().modbus_unhealthy_wait" required
                        :dark="$store.getters.getColorPalette().isDark"></v-text-field>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                </v-col>
                <v-col>
                    <v-select v-if="!loading" v-model="form.multiprocessing" :items="boolOptions"
                        :label="$store.getters.getTextMap().fast_mode" :dark="$store.getters.getColorPalette().isDark"
                        dense filled item-text="label" item-value="value" required
                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense
                        :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                        <thead
                            :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">

                            <tr>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                    :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                    Connectors</th>
                                <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                    :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                    Actions</th>
                            </tr>
                            <tr v-for="(item) in Object.keys(connectors)" :key="item"
                                :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                <td class="text-center">{{ connectorNameMap[item]?connectorNameMap[item]:item }}</td>
                                <td class="text-center">
                                    <v-checkbox v-model="connectors[item]" 
                                        :dark="$store.getters.getColorPalette().isDark"></v-checkbox>

                                </td>
                            </tr>

                        </thead>

                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">
                        {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor"
                        :dark="$store.getters.getColorPalette().isDark" @click="close">
                        {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>

</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name: 'UpdateHubSpec',
    props: ['hub'],
    components: {
        InfoAlert
    },
    data() {
        return {
            loading: false,
            showDismissibleAlert: false,
            info: '',
            valid: false,
            form: {
                modbus_latency: 0,
                modbus_unhealthy_latency: 0,
                multiprocessing: false,
                meta:{}
            },
            connectorNameMap:{
                modbus_tcp:'Modbus TCP',

            },
            connectors: {
                     'modbus_tcp':true,
                'modbus_rtu':true,
                    's7_comm':true,
                    'opc_ua':true,
                 'mqtt_connector':true
        },

            rules: FormRules.rules,
            boolOptions: [
                { label: this.$store.getters.getTextMap().on, value: true },
                { label: this.$store.getters.getTextMap().off, value: false }
            ],
        }
    },
    mounted() {
        // console.log(this.view,'view_id')
        this.init()

    },
    computed:{
        // connectorList(){
        //     let op=[]
        //     for(let i of Object.keys(this.connectors)){
        //         op.push({
        //             name:this.connectorNameMap[i]?this.connectorNameMap[i]:i,
        //             value:i
        //         })
               
        //     }
        //     return op
        // },
    },
    methods: {
        init() {
            // console.log(this.view)
            if (this.hub && this.hub.hub_id) {
                this.form.modbus_latency = this.hub.modbus_latency
                this.form.modbus_unhealthy_latency = this.hub.modbus_unhealthy_latency
                this.form.multiprocessing = this.hub.multiprocessing
                if(this.hub.meta){
                    this.connectors=this.hub.meta.connectors
                    this.form.meta = this.hub.meta

                }
            }
        },
        onSubmit() {
            
            this.$refs.viewForm.validate()
            if (this.valid) {
                this.loading = true
                this.form.meta={connectors:this.connectors}
                let d = Object.assign({}, this.form)
                // console.log(d)
                d['hub_id'] = this.hub.hub_id
                axios.post(this.$store.state.api + 'updateHubOTConfigs', d, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status == 'success') {
                            this.loading = false
                            this.$store.dispatch('refreshHubs')
                            this.$emit('update')
                            this.close()
                        } else {
                            this.loading = false
                            this.info = response.data.msg
                            this.showDismissibleAlert = true
                        }
                    }).catch(err => {
                        this.loading = false
                        this.info = err
                        this.showDismissibleAlert = true

                    })
            }
        },
        close() {
            this.$emit('close')
        }
    },
    watch: {
        hub: {
            handler() {

                this.init()
            },
            deep: true
        },
    }
}
</script>